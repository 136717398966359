<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
  ></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input
      class="form-control input"
      type="text"
      placeholder="Explore Members List..."
      tabindex="-1"
      data-search="search"
      (keyup)="searchUpdate($event)"
      [(ngModel)]="searchText"
      #openSearch
    /><button class="btn search-input-close p-0" (click)="toggleSearch()"><span [data-feather]="'x'"></span></button>

    <!-- Search List -->
    <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
      
      <!-- Members -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Members</h6></a>
      </li>
      <ng-container *ngIf="(contacts | filter: searchText:'title').length; else noResultsMembers">
        <li class="auto-suggestion" *ngFor="let contact of contacts | filter: searchText:'title' | slice: 0:4">
          <a 
            class="d-flex align-items-center justify-content-between py-50 w-100" 
            href="javascript:void(0)"
            (keyup.enter)="navigate(contact.link)"
            [routerLink]="contact.link"
            (click)="toggleSearch()"

            ><div class="d-flex align-items-center">
              <div
                class="avatar mr-1 ml-0"
                [ngClass]="{
                  'bg-light-success': contact.status == 'active',
                  'bg-light-primary': contact.status == 'processing',
                  'bg-light-secondary': contact.status == 'inactive',
                  'bg-light-warning': contact.status == 'pending',
                  'bg-light-danger': contact.status == 'overdue',
                  'bg-light-info': contact.status == 'scheduled'
                  
                }"
              >
                <div class="avatar-content">{{ contact.title | initials }}</div>
              </div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.title }}</p>
                <small class="text-muted">{{ contact.email }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">{{ contact.date }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsMembers>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found.</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Members -->

    </ul>
    <!--/ Search List -->
  </div>
</li>
