import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";

import { environment } from "environments/environment";
import { User } from "app/auth/models";

@Injectable({ providedIn: "root" })
export class CustomerService {

  public rows;
  public onCustomerViewChanged: BehaviorSubject<any>;
  public onPaymentMethodsChanged: BehaviorSubject<any>;
  /**
   *
   * @param {HttpClient} _http
   */
  constructor(private _http: HttpClient) {

    // Set the defaults
        this.onCustomerViewChanged = new BehaviorSubject({});    
        this.onPaymentMethodsChanged = new BehaviorSubject({});
  }

  /**
   * Get customer by id
   */
  getById() {
    const token = localStorage.getItem("token");

    return this._http.get(`${environment.apiUrl}/customer`);
  }

  /**
   * Get customer by id
   */
  updateById(data) {
    return new Promise((resolve, reject) => {
      this._http
        .patch(`${environment.apiUrl}/customer/${data.id}`, data)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  /**
   * Get locations by customer id
   */
  getLocations() {
    return this._http.get(`${environment.apiUrl}/location`);
  }

  /**
   * Activate Location
   */
  activateLocation(id, return_link) {
    return new Promise((resolve, reject) => {
      this._http
        .post(`${environment.apiUrl}/location/${id}/activate`, return_link)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Activate Location
   */
  inactivateLocation(id) {
    return new Promise((resolve, reject) => {
      this._http
        .patch(`${environment.apiUrl}/location/${id}/inactivate`, id)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Create Location Link
   */
  createLocationLink(info) {
    return new Promise((resolve, reject) => {
      this._http
        .post(`${environment.apiUrl}/campaign`, info)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Delete Location Link
   */
  deleteLocationLink(token) {
    return new Promise((resolve, reject) => {
      this._http
        .delete(`${environment.apiUrl}/campaign/${token}`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

      /**
 * Get locations by customer id 
 */
       getUsersList() {
        return this._http.get(`${environment.apiUrl}/user`);
      }

      /**
   * Get Subscription by customer id 
   */
      getSubscriptionList() {
        return new Promise((resolve, reject) => {            

          this._http.get(`${environment.apiUrl}/subscription`)
          .subscribe((response: any) => {
            resolve(response);
          }, reject);       
        });

      }

  addNewPaymentMethod(customerId, return_link): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .post(
          `${environment.apiUrl}/customer/paymentmethod/${customerId}`,
          return_link
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  inactivatePaymentMethod(paymentmethod_id): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .patch(
          `${environment.apiUrl}/customer/paymentmethod/${paymentmethod_id}/inactivate`,
          null
        )
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  editPaymentMethod(paymentmethod_id, data): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this._http
        .patch(
          `${environment.apiUrl}/customer/paymentmethod/${paymentmethod_id}/edit`,
          data
        )
        .subscribe((response: any) => {          
          // this.onCustomerViewChanged.next(this.rows);

          resolve(response);
        }, reject);
    });
  }

  changeSubscription(planId, contractCycle) {
    return new Promise((resolve, reject) => {            
      this._http.patch(`${environment.apiUrl}/subscription/change`, {"planId":planId,"contractCycle": contractCycle}).subscribe((response: any) => {
        resolve(response);
      }, reject);       
    });
  };

    /**
   * Get Statement  
   */
    getStatement() {
      return new Promise((resolve, reject) => {            
  
        this._http.get(`${environment.apiUrl}/customer/statement`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);       
      });
  
    };

  

}